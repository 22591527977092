<template>
	<div class="editInfoContent">
		<el-tabs v-model="activeName">
			<el-tab-pane label="基本信息" name="first">
				<div class="info">
					<el-form ref="formMsg" :model="modelList" label-width="120px">
						<div class="infoPortrait">
							<div>
								<el-upload class="avatar-uploader"
									action="https://api.jjyxt.cn/user/userSys/upLoadPhoto" :show-file-list="false"
									:on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload"
									:headers="{token}">
									<div class="imgToux">
										<div v-if="imageUrl">
											<img :src="imageUrl" class="avatar">
											<div class="modeImg">
												<span>更换头像</span>
											</div>
										</div>
										<img v-else src="../../assets/imgs/3.png" class="avatar">
										<div class="modeImg">
											<span>更换头像</span>
										</div>
									</div>
								</el-upload>
							</div>
							<p>{{name}}</p>
						</div>
						<el-form-item v-for="(item,index) in filterUserInfo" :key="index" :prop="item.key"
							:label="item.label+':'" class="form_item"
							:rules="[{ required: item.choose == 1, message: `${item.label}不能为空`} ]">
							<template v-if="item.type == 2">
								<!-- 单列 -->
								<el-select v-model="modelList[item.key]" :placeholder="'请选择'+item.label"
									v-if="item.sourse == 3">
									<el-option v-for="(it,j) in item.list" :key="j" :label="it.label"
										:value="it.label" />
								</el-select>
								<!-- 所属区域 -->
								<el-cascader v-model="modelList[item.key]" :options="cityData"
									:props="{value:'name',label:'name'}" v-if="item.sourse == 1" />
								<!-- 职业 -->
								<el-cascader v-model="modelList[item.key]" :options="item.list"
									:props="{value:'id',label:'name'}" v-if="item.sourse == 2" />
							</template>
							<template v-else>
								<el-input v-model="modelList[item.key]" :placeholder="`请输入${item.label}`"
									:disabled="item.key === 'phoneNumber' || item.key === 'idCard'" />
								<el-button type="primary" v-if="item.key === 'phoneNumber'" class="btn"
									@click="handleRebind('phone')">重绑手机号</el-button>
								<el-button type="primary" v-if="item.key === 'idCard'" class="btn"
									@click="handleRebind('idcard')">重绑身份证</el-button>
							</template>
						</el-form-item>
					</el-form>
					<div class="onSumbit">
						<el-button type="primary" @click="saveEvt">保存</el-button>
						<el-button type="danger" @click="handleRemoveAccount">注销账号</el-button>
					</div>
				</div>
				<!-- 重绑手机号弹窗 -->
				<el-dialog :title="rebindKey" :visible.sync="dialogFormVisible" append-to-body width="30%">
					<el-form :model="phoneForm" label-width="120px">
						<el-form-item label="手机号:">
							<el-input v-model="phoneForm.phoneNumber" autocomplete="off" style="width:80%" />
						</el-form-item>
						<el-form-item label="短信验证码:">
							<el-input v-model="phoneForm.code" autocomplete="off" style="width:50%" />
							<el-button @click="handleGetCode()" :disabled="btnFlag">
								{{ result > 0 && result ? time2: "获取验证码" }}
							</el-button>
						</el-form-item>
						<el-form-item label="身份证号码:">
							<el-input v-model="phoneForm.idCard" autocomplete="off" style="width:80%" />
						</el-form-item>
					</el-form>
					<div slot="footer" class="dialog-footer" style="text-align:center">
						<el-button @click="dialogFormVisible = false">取 消</el-button>
						<el-button type="primary" @click="handlePhoneFormSumbit">确 定</el-button>
					</div>
				</el-dialog>
				<!-- 注销账号 -->
				<el-dialog title="注销协议" :visible.sync="dialogVisible" append-to-body width="1200" class="status_change">
					<div class="dialogContain" style="padding:0 20px;" v-html="protocolText"></div>
					<span slot="footer" class="dialog-footer">
						<el-button style="background-color: #1D5DE0" @click="handleAgreeDestroy">同 意</el-button>
						<el-button @click="dialogVisible = false">拒 绝</el-button>
					</span>
				</el-dialog>
			</el-tab-pane>
			<el-tab-pane label="修改密码" name="second">
				<div class="info" style="margin-top:50px">
					<el-form ref="form" :model="psd" :rules="formRules" label-width="120px">

						<el-form-item label="旧密码:" prop="oldPsd">
							<el-input type="password" v-model="psd.oldPsd" placeholder="请输入旧密码" show-password>
							</el-input>
						</el-form-item>
						<el-form-item label="新密码:" prop="newPsd">
							<el-input type="password" v-model="psd.newPsd" placeholder="请输入新密码" show-password>
							</el-input>
						</el-form-item>
						<el-form-item label="确认新密码:" prop="sureNewPsd">
							<el-input type="password" v-model="psd.sureNewPsd" placeholder="请确认新密码" show-password>
							</el-input>
						</el-form-item>

					</el-form>
					<div class="onSumbit">
						<el-button type="primary" @click="sumbitEvt">确定修改</el-button>
					</div>
				</div>
			</el-tab-pane>
		</el-tabs>
	</div>
</template>

<script>
	import md5 from 'js-md5';
	import test from '../../utils/test.js';
	import cityData from '../../utils/city.js';
	import {
		mapState,
		mapGetters
	} from 'vuex';
	export default {
		name: 'EditInfo',
		data() {
			return {
				rebindKey: '',
				cityData: cityData,
				dialogFormVisible: false,
				dialogVisible: false,
				phoneForm: {},
				activeName: 'first',
				form: {},
				imageUrl: '',
				name: '',
				modelList: {},
				formList: [],
				psd: {
					oldPsd: '',
					newPsd: '',
					sureNewPsd: '',
					id: ''
				},
				userData: [],
				status1: true,
				isPassword: '',
				formRules: {
					oldPsd: [{
						required: true,
						message: '请输入旧密码',
						trigger: 'blur'
					}],
					newPsd: [{
						required: true,
						message: '请输入新密码',
						trigger: 'blur'
					}],
					sureNewPsd: [{
							required: true,
							message: '请第二次输入新密码',
							trigger: 'blur'
						},
						{
							validator: (rule, value, callback) => {
								if (value.length < 6 || value.length > 200) {
									callback(new Error('密码不能小于6位数'))
								} else {
									callback()
								}
							},
							required: true,
						}
					]
				},
				btnFlag: false,
				timer: null,
				result: null, //此变量为截止时间戳与当前时间戳相减的差
				timeStamp: null, //此变量为倒计时截止的时间戳
				time2: null,
				protocolText: '', //注销协议
			};
		},
		computed: {
			...mapState(['token', 'avatar', 'userInfo']),
			...mapGetters(['filterUserInfo', 'doneUserInfo']),
		},
		created() {
			const res = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,16}$/
			// if (this.$route.query.isUpdate === '0') {
			// 	this.activeName = 'second'
			// }
			setTimeout(() => {
				this.status1 = false
			}, 2000)
			this.getTxImg()
			if (this.doneUserInfo) {
				this.modelList = Object.assign({}, this.doneUserInfo)
				if (this.modelList.place) {
					this.modelList.place = this.modelList.place.split('/')
				}
				if (this.modelList.industry) {
					let val = [];
					let arr = this.filterUserInfo.filter(item => {
						return item.key === 'industry'
					})

					let arr1 = this.modelList.industry.split('/')

					arr[0].list.forEach(ele => {
						if (arr1[0] === ele.name) {
							val.push(ele.id)
						}
						ele.children.forEach(item => {
							if (item.name == arr1[1]) {
								val.push(item.id)
							}
						})
					});
					this.modelList.industry = val;
				}
			}
		},
		methods: {
			// 获取站点注销协议
			getProtocol() {
				this.$http.post('getProtocol', {
					name: '注销协议'
				}).then(res => {
					if (res.code == 200) {
						this.protocolText = res.data.content;
						return true
					} else {
						this.$message.error(res.message)
						return false
					}
				}).then(res => {
					if (res) this.dialogVisible = true
				})
			},
			//倒计时时间
			countTime() {
				//如果result<=0,证明上一次读秒已结束，需要重新设置新的时间戳
				if (!this.result) {
					let currentStamp = new Date().getTime();
					this.timeStamp = new Date().getTime() + 300 * 200; //设置倒计时时间
					this.result = (this.timeStamp - currentStamp) / 1000;
					this.time2 = this.formatSeconds(this.result)
					this.result--;
				}
				this.timer = setInterval(() => {
					this.time2 = this.formatSeconds(this.result)
					this.result--;
					if (this.result === 0) {
						clearInterval(this.timer);
						this.timer = null;
						this.btnFlag = false;
					}
				}, 1000);
			},
			//时间转换时分秒
			formatSeconds(value) {
				let result = parseInt(value)
				let h = Math.floor(result / 3600) < 10 ? '0' + Math.floor(result / 3600) : Math.floor(result / 3600);
				let m = Math.floor((result / 60 % 60)) < 10 ? '0' + Math.floor((result / 60 % 60)) : Math.floor((result /
					60 % 60));
				let s = Math.floor((result % 60)) < 10 ? '0' + Math.floor((result % 60)) : Math.floor((result % 60));
				let res = '';
				if (h !== '00') res += `${h}时`;
				if (m !== '00') res += `${m}分`;
				res += `${s}秒`;
				return res;
			},
			// 重新绑定
			handleRebind(type) {
				switch (type) {
					case 'phone':
						this.rebindKey = '重绑手机号';
						break;
					case 'idcard':
						this.rebindKey = '重绑身份证';
						break;
				}
				this.phoneForm = {};
				this.timer = null;
				this.result = null;
				this.timeStamp = null;
				this.time2 = null;
				this.btnFlag = false;
				this.dialogFormVisible = true;
			},
			// 获取验证码
			handleGetCode() {
				if (!test.mobile(this.phoneForm.phoneNumber)) return this.$message.error('请输入正确手机号')
				let url = 'catRegisterGetCode';
				if (this.rebindKey === '重绑身份证') {
					url = 'identityGetCode'
				}
				if (!this.btnFlag) {
					this.btnFlag = !this.btnFlag;
					this.countTime();
					this.$http.post(url, {
						phone: this.phoneForm.phoneNumber
					}).then(res => {
						if (res.code == 200) {
							this.$message.success('请查看你的手机并输入验证码')
						} else {
							this.$message.error(res.message)
						}
					})

				}

			},
			// 保存切换手机号
			handlePhoneFormSumbit() {
				if (!test.mobile(this.phoneForm.phoneNumber)) return this.$message.error('请输入正确手机号')
				if (!test.code(this.phoneForm.code)) return this.$message.error('请输入正确验证码')
				if (!test.idCard(this.phoneForm.idCard)) return this.$message.error('请输入正确身份证号')
				let url = 'catPhoneNumber';
				let formData = {
					code: this.phoneForm.code,
					phoneNumber: this.phoneForm.phoneNumber,
					idCard: this.phoneForm.idCard,
				}
				if (this.rebindKey === '重绑身份证') {
					url = 'identityUpdate'
					delete formData.phoneNumber
				}
				this.$http.post(url, formData).then(res => {
					if (res.code == 200) {
						this.$message.success('绑定成功')
						let data = JSON.parse(JSON.stringify(this.userInfo));
						if (this.rebindKey === '重绑手机号') {
							data.forEach(item => {
								if (item.label.split(":")[1] === 'phoneNumber') {
									item.value = this.phoneForm.phoneNumber
								}
							})
							this.modelList.phoneNumber = this.phoneForm.phoneNumber;
						}
						if (this.rebindKey === '重绑身份证') {
							data.forEach(item => {
								if (item.label.split(":")[1] === 'idCard') {
									item.value = this.phoneForm.idCard
								}
							})
							this.modelList.idCard = this.phoneForm.idCard;
						}
						this.$store.commit('SET_USER_INFO', data)
						this.dialogFormVisible = false;
						this.phoneForm = {};
					} else {
						this.$message.error(res.message)
					}
				})
			},
			// 获取用户头像
			getTxImg() {
				this.$http.post('queryLoginPhoto').then(res => {
					if (res.code == 200) {
						this.imageUrl = res.data
					}
				})
			},
			handleAvatarSuccess(res, file) {
				if (res.code === 200) {
					this.$message.success('修改头像成功,请刷新页面')
					this.imageUrl = URL.createObjectURL(file.raw);
					this.$store.commit('SET_AVATAR', URL.createObjectURL(file.raw))
				} else {
					this.$message.error(res.message)
				}
			},
			beforeAvatarUpload(file) {
				const isJPG = file.type === 'image/jpeg';
				const isLt2M = file.size / 1024 / 1024 < 2;

				if (!isJPG) {
					this.$message.error('上传头像图片只能是 JPG 格式!');
				}
				if (!isLt2M) {
					this.$message.error('上传头像图片大小不能超过 2MB!');
				}
				return isJPG && isLt2M;
			},
			// 修改信息
			saveEvt() {
				if (!test.idCard(this.modelList.idCard)) return this.$message.error('请输入正确身份证号');
				if (!test.nameReg(this.modelList.name)) return this.$message.error('请输入正确姓名');
				if (!this.modelList.place) return this.$message.error('请选择所属地区');
				if (!this.modelList.industry) return this.$message.error('请选择职业');
				this.modelList.place = this.modelList.place.join('/')
				this.modelList.industry = this.modelList.industry[this.modelList.industry.length - 1]
				this.$refs.formMsg.validate((valid) => {
					if (valid) {
						this.$http.post('updataInfo', {
							data: JSON.stringify(this.modelList)
						}).then(res => {
							if (res.code === 200) {
								localStorage.removeItem('isUpdate')
								this.$message.success('修改成功，将自动返回登录页面...')
								setTimeout(() => {
									this.$store.dispatch('LoginOut')
								}, 2000)
							} else {
								this.$message.error(res.message)
							}
						})
					}
				})

			},
			// 注销账号
			handleRemoveAccount() {
				this.$confirm('此操作将永久删除该账号, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'error'
				}).then(() => {
					this.getProtocol();
				})
			},
			// 确定注销账号
			handleAgreeDestroy() {
				this.$http.post('destroyAccount').then(res => {
					if (res.code == 200) {
						this.$message.success('注销成功')
						setTimeout(() => {
							this.$store.dispatch('LoginOut')
						}, 1500);
					} else {
						this.$message.error(res.message)
					}
				})

				this.dialogVisible = false;
			},
			//修改密码
			sumbitEvt() {
				this.$refs.form.validate((valid) => {
					if (valid) {
						if (this.psd.newPsd === this.psd.sureNewPsd && !!this.psd.newPsd) {
							this.psd.oldPsd = md5(this.psd.oldPsd)
							this.psd.newPsd = md5(this.psd.newPsd)
							this.psd.sureNewPsd = md5(this.psd.sureNewPsd)
							this.$http.post('updataPassword', {
								id: this.psd.id,
								oldPassword: this.psd.oldPsd,
								password: this.psd.newPsd
							}).then(res => {
								if (res.code == 200) {
									this.$message.success('修改密码成功，请重新登录')
									setTimeout((() => {
										this.$store.dispatch('LoginOut')
										localStorage.removeItem('isUpdate')
										// this.$router.push({
										// 	path: '/',
										// 	query: {
										// 		index: 1
										// 	}
										// })
									}), 1000)
								} else {
									this.$message.error(res.message)
								}
							})
						} else {
							this.$message.error('两次密码输入不一致')
						}
					}
				})

			}
		}
	}
</script>

<style lang="less" scoped>
	.btn {
		position: absolute;
		margin-left: 10px;
	}

	.editInfoContent {
		width: 974px;
		margin-bottom: 100px;
		padding-bottom: 50px;
		background-color: #fff;

		/deep/ .el-tabs__item {
			font-size: 24px;
			padding-bottom: 19px;
			padding-left: 21px !important;
			line-height: 63px;
			height: 63px;
		}

		/deep/ .el-tabs__nav-scroll {
			height: 63px;
		}

		/deep/ .el-tabs__active-bar {
			// left: 24px;
			background-color: #F02610;
		}

		/deep/ .el-tabs__item:hover {
			color: #F02610;
		}

		/deep/.el-tabs__item.is-active {
			color: #F02610;
		}
		// /deep/.el-form-item label:after {
    //     content: " ";
    //     display: inline-block;
    //     width: 100%;
    // }
 
    // /deep/.el-form-item__label {
    //     text-align: left
    // }
   

	}

	.info {
		width: 352px;
		padding-left: 30px;

		.infoPortrait {
			width: 82px;
			text-align: center;
			margin-left: 120px;

			p {
				margin-top: 15px;
				margin-bottom: 50px;
				color: #333333;
				font-size: 14px;
				font-weight: 500;
			}

			.imgPortrait {
				width: 82px;
				height: 82px;
				border-radius: 50%;
			}
		}

		// height: 741px;
		.onSumbit {
			text-align: center;

			// /deep/ .el-button {
			// 	color: #FFFFFF;
			// 	font-size: 14px;
			// 	padding: 15px 53px;
			// 	background: linear-gradient(163deg, #E51E0B 0%, #FF6759 100%);
			// }
		}
	}

	.avatar-uploader .el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}

	.avatar-uploader .el-upload:hover {
		border-color: #409EFF;
	}

	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 178px;
		height: 178px;
		line-height: 178px;
		text-align: center;
	}

	.avatar {
		width: 82px;
		height: 82px;
		border-radius: 50%;
		display: block;
	}

	//  .avatar-uploader::after{
	//    content: '';
	//     width: 82px;
	//     height: 82px;
	//     border-radius: 50%;
	//     display: block;
	//  }
	.imgToux {
		position: relative;
	}

	.imgToux:hover .modeImg {
		display: block;
	}

	.modeImg {
		display: none;
		font-size: 12px;
		color: #F02610;
		position: absolute;
		top: 0;
		left: 0;
		width: 82px;
		height: 82px;
		background-color: #000;
		opacity: 0.6;
		border-radius: 50%;
		line-height: 82px;
		text-align: center;

		& span {
			padding: 2px 8px;
			background-color: #E1E1E1;
			border-radius: 8px;
		}
	}

	.yzm {
		width: 300px;
		display: flex;
		justify-content: space-between;

		/deep/.el-input__inner {
			font-size: 14px;
			padding: 0 15px 0 30px !important;
		}

		margin-top: 34px;
		display: flex;
		position: relative;

		/deep/.el-input {
			width: 196px;
			height: 41px;
			border-radius: 5px 0px 0px 5px;

			/deep/.el-input__inner {
				padding: 0 30px;
			}
		}

		img {
			position: absolute;
			width: 12px;
			height: 15px;
			left: 10px;
			top: 12px;
		}

		div {
			width: 108px;
			height: 40px;
			border-radius: 0px 5px 5px 0px;
			font-size: 16px;
			font-family: PingFang SC;
			font-weight: 400;
			color: #010101;
			text-align: center;
			// line-height: 38px;
			cursor: pointer;

			button {
				width: 108px;
				height: 40px;
				background: #FFFFFF;
			}
		}
	}

	.status_change {
		/deep/.el-dialog {
			// height: 800px;
			overflow: auto;
			margin-top: 50px !important;
		}

		/deep/ .el-checkbox__input.is-checked .el-checkbox__inner {
			background-color: #E51E0B;
			border-color: #E51E0B
		}

		::v-deep .el-dialog {
			border-radius: 10px;

			.el-dialog__header {
				background-color: #FC772F;
				font-size: 18px;
				font-weight: 700;
				text-align: center;

				.el-dialog__title,
				.el-dialog__headerbtn i {
					color: white;
				}
			}

			.el-dialog__body {
				.dialogContain {
					overflow-y: auto;
					height: 400px !important;
				}
			}

			.el-dialog__footer {
				text-align: center;

				.el-button {
					color: #fff;
					font-size: 14px;
					background-color: #E01D1D;
					width: 120px;
					height: 20px;
					line-height: 0;
					border-radius: 10px;
				}
			}

		}
	}
</style>
