<template>
	<div class="editInfoContent">
		<el-tabs v-model="activeName" @tab-click="handleClick">
			<el-tab-pane label="申请开票" name="first">
				<!-- v-if="tableData.length> 0" -->
				<div v-if="tableData.length> 0" class="info mysInvoice">
					<div class="infoCls">
						<el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%"
							@selection-change="handleSelectionChange">
							<el-table-column type="selection" width="55" :selectable='checkSelectable' >
							</el-table-column>

							<el-table-column prop="orderCode" label="订单编号">
							</el-table-column>
							<el-table-column prop="courseName" label="产品名称" width="120">
							</el-table-column>
							<!-- <el-table-column prop="status"
                             label="类型"
                             width="80"
                             >
            </el-table-column> -->
							<el-table-column prop="payTime" label="订单支付时间" width="160">
							</el-table-column>
							<el-table-column prop="unitPrice" label="订单实付金额">
							</el-table-column>
							<el-table-column prop="unitPrice" label="可开票金额" style="color:red">
							</el-table-column>
							<el-table-column label="操作" style="color:red">
								<!-- <el-button class="shengqbtn" @click="shengqings(item)">申请开票</el-button> -->
								<template slot-scope="scope">
									<el-button class="shengqbtn" @click="shengqings(scope.row)" v-if="scope.row.invoice !== 2">申请开票</el-button>
									<el-button class="shengqbtn"  v-else>开票中</el-button>
								</template>
							</el-table-column>
						</el-table>
						<div class="block">
							<div style="text-align: right;margin-top: 20px">
								<el-pagination background @size-change="handleSizeChange"
									@current-change="handleCurrentChange" :current-page="formInline.page"
									:page-sizes="[5, 10, 20, 30]" :page-size="formInline.pageSize"
									layout="total, sizes, prev, pager, next, jumper" :total="formInline.total">
								</el-pagination>
							</div>
						</div>
					</div>
					<div class="checkedAll">
						<div></div>
						<div>
							<span>￥</span> <span>{{allMoney}}</span>
							<el-button class="bnt1" @click="shengqing">申请开票</el-button>
						</div>
					</div>
					<!-- 申请发票弹出框 -->
					<el-dialog title="开票" :visible.sync="dialogVisible" width="658px" :modal='false'
						:before-close="handleClose">
						<div class="infoL">
							<el-form label-position="left" label-width="120px" ref="form" :rules="rules"
								:model="formLabelAlign">
								<el-form-item label="开具类型：" prop="type">
									<el-radio-group @change="radioEvt" v-model="formLabelAlign.type">
										<el-radio label='1'>个人/非企业单位</el-radio>
										<el-radio label='2'>企业单位</el-radio>
									</el-radio-group>
								</el-form-item>
								<div v-if="!typeStatus">
									<el-form label-position="left" label-width="120px" ref="company" :rules="rules"
										:model="formLabelAlign">
										<el-form-item label="抬头：" prop="name">
											<el-input v-model="formLabelAlign.name" placeholder="请输入抬头"></el-input>
										</el-form-item>
										<el-form-item prop="taxnum" label="公司税号：">
											<el-input v-model="formLabelAlign.taxnum" placeholder="请输入公司税号"></el-input>
										</el-form-item>
										<el-form-item label="手机号：" prop="phone">
											<el-input v-model="formLabelAlign.phone" placeholder="请输入手机号"></el-input>
										</el-form-item>
										<el-form-item label="邮箱：" prop="email">
											<el-input v-model="formLabelAlign.email" placeholder="请输入邮箱"></el-input>
										</el-form-item>
										<div>
											<p @click="more" class="more_title"><span>{{ative?'收起':'更多内容'}}</span><i
													class="iconfont i"
													:class="ative?'icon-xiajiantou':'icon-shangjiantou'"></i></p>
											<div :class="ative==1?'more_content':'no_content' ">
												<el-form-item label="开户银行：">
													<el-input v-model="formLabelAlign.account" placeholder="请输入开户行">
													</el-input>
												</el-form-item>
												<el-form-item label="银行账户：">
													<el-input v-model="formLabelAlign.depositBank"
														placeholder="请输入银行账户"></el-input>
												</el-form-item>
												<el-form-item label="地址：">
													<el-input v-model="formLabelAlign.address" placeholder="请输入地址">
													</el-input>
												</el-form-item>
												<el-form-item label="电话：">
													<el-input v-model="formLabelAlign.buyerTel" placeholder="请输入电话号">
													</el-input>
												</el-form-item>
												<el-form-item label="备注说明">
													<el-input v-model="formLabelAlign.remarks"
														placeholder="请填写需要在发票备注栏内容"></el-input>
												</el-form-item>
											</div>
										</div>

									</el-form>


								</div>
								<div v-if="typeStatus">
									<el-form label-position="left" label-width="120px" ref="personal" :rules="rules"
										:model="formLabelAlign">
										<el-form-item label="抬头" prop="name">
											<el-input v-model="formLabelAlign.name" placeholder="请输入抬头"></el-input>
										</el-form-item>
										<el-form-item label="手机号：" prop="phone">
											<el-input v-model="formLabelAlign.phone" placeholder="请输入手机号"></el-input>
										</el-form-item>
										<el-form-item label="邮箱：" prop="email">
											<el-input v-model="formLabelAlign.email" placeholder="请输入邮箱"></el-input>
										</el-form-item>
										<div>
											<p @click="more" class="more_title"><span>{{ative?'收起':'更多内容'}}</span><i
													class="iconfont i"
													:class="ative?'icon-xiajiantou':'icon-shangjiantou'"></i></p>
											<div :class="ative==1?'more_content':'no_content' ">
												<el-form-item label="开户银行：">
													<el-input v-model="formLabelAlign.account" placeholder="请输入开户行">
													</el-input>
												</el-form-item>
												<el-form-item label="银行账户：">
													<el-input v-model="formLabelAlign.depositBank"
														placeholder="请输入银行账户"></el-input>
												</el-form-item>
												<el-form-item label="地址：">
													<el-input v-model="formLabelAlign.address" placeholder="请输入地址">
													</el-input>
												</el-form-item>
												<el-form-item label="电话：">
													<el-input v-model="formLabelAlign.buyerTel" placeholder="请输入电话">
													</el-input>
												</el-form-item>
												<el-form-item label="备注说明">
													<el-input v-model="formLabelAlign.remarks"
														placeholder="请填写需要在发票备注栏内容"></el-input>
												</el-form-item>
											</div>
										</div>

									</el-form>

								</div>
							</el-form>
							<p class="fontStyle">温馨提示：目前平台只提供电子普通发票,申请后发票不能作废，并且不能退款！</p>
							<div>
								<el-button style="margin:0 auto;display:block" class="bnt" size="mini" @click="sureBtn">
									确认开票</el-button>
							</div>
						</div>
					</el-dialog>
				</div>
				<div class="noTeacter" v-else-if="tableData.length === 0">
					<div class="noTeacter_img">
						<img src="../../assets/imgs/4.png" alt="">
						<div>抱歉，暂时没有可开发票的订单</div>
					</div>
				</div>
			</el-tab-pane>
			<el-tab-pane label="开票记录" name="second">
				<div v-if="tableData.length > 0" class="info">
					<el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%">

						<el-table-column prop="orderCode" label="订单编号">
						</el-table-column>
						<el-table-column prop="courseName" label="产品名称" width="120">
						</el-table-column>
						<el-table-column prop="invoiceStatus" label="发票类型" width="80">
							<template slot-scope="scope">{{ scope.row.invoiceType === 1 ? '电子发票' : '电子发票' }}</template>
						</el-table-column>
						<el-table-column prop="invoiceTime" label="申请时间">
						</el-table-column>
						<el-table-column prop="money" label="支付金额">
						</el-table-column>
						<el-table-column prop="auditStatus" label="操作" style="color:red">
							<template slot-scope="scope">{{ scope.row.invoiceStatus === 1 ? '正在开票' : '已开票' }}</template>
						</el-table-column>
					</el-table>

					<div v-if="formInline.total > 0" style="text-align: right;margin-top: 20px">

						<el-pagination background @size-change="handleSizeChangeHis"
							@current-change="handleCurrentChangeHis" :current-page="formInline.page"
							:page-sizes="[5, 10, 20, 30]" :page-size="formInline.pageSize"
							layout="total, sizes, prev, pager, next, jumper" :total="formInline.total">
						</el-pagination>
					</div>
				</div>
				<div class="noTeacter" v-else-if="tableData.length === 0">
					<div class="noTeacter_img">
						<img src="../../assets/imgs/4.png" alt="">
						<div>抱歉，您还没有开过发票</div>
					</div>
				</div>
			</el-tab-pane>
		</el-tabs>
	</div>
</template>

<script>
	import test from '../../utils/test'
	export default {
		name: 'MyInvoice',
		data() {
			return {
				formInline: {
					page: 1,
					pageSize: 10,
					total: 0,
					order: '',
					orderCode: '',
					type: ''

				},
				activeName: 'first',
				tableData: [],
				checked: false,
				allMoney: 0,
				count: 0,
				ative: 0,
				multipleSelection: [],
				dialogVisible: false,
				formLabelAlign: {
					type: '',
					account: '', //开户行地址

					depositBank: '',
					email: '', //邮箱
					name: '', //抬头
					phone: '', //手机号
					orderId: '',
					taxnum: "", //税号
					buyerTel: '', //电话
					remarks: '', //备注
					address: '', //地址
				},
				rules: {
					type: [{
						required: true,
						message: '请选择个人开票还是公司开票',
						trigger: 'blur'
					}],


					taxnum: [{
						required: true,
						message: '请输入公司税号',
						trigger: 'blur'
					}],
					buyerTel: [{
						required: true,
						message: '请输入电话号',
						trigger: 'blur'
					}],
					email: [{
							required: true,
							message: '请输入邮箱',
							trigger: 'blur'
						},
						{
							validator: (rule, value, cb) => {
								const reg = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(\.[a-zA-Z0-9_-])+/;
								if (reg.test(value)) {
									return cb();
								} else {
									cb(new Error('请输入合法的邮箱！'));
								}
							},
							trigger: 'blur'
						}
					],
					name: [{
							required: true,
							message: '请输入抬头',
							trigger: 'blur'
						}

					],
					phone: [{
							required: true,
							message: '请输入手机号',
							trigger: 'blur'
						},
						{
							validator: (rule, value, cb) => {
								const reg =
									/^(0|86|17951)?(13[0-9]|15[0123456789]|17[678]|18[0-9]|14[57])[0-9]{8}$/;
								if (reg.test(value)) {
									return cb();
								} else {
									cb(new Error('请输入合法的手机号码！'));
								}
							},
							trigger: 'blur'
						}

					],
				},
				typeStatus: true,
			};
		},
		created() {
			this.getList()
		},
		methods: {
			checkSelectable(row, index) {
				if (row.unitPrice && row.invoice !== 2) {
					return true
				} else {
					return false
				}
			},
			// 初始化数据
			getList() {
				// 获取申请订单列表
				this.$http.post('myInvoiceApi', {
					pageNumber: this.formInline.page,
					pageSize: this.formInline.pageSize,
				}).then(res => {
					if (res.code === 200) {
						this.tableData = res.data.records
						this.formInline.total = res.data.total
					}
				})
			},
			// 获取开票历史
			getListHistoray() {
				this.$http.post('myInvoice', {
					pageNumber: this.formInline.page,
					pageSize: this.formInline.pageSize
				}).then(res => {
					if (res.code === 200) {

						this.tableData = res.data.records
						this.formInline.total = res.data.total
						console.log(this.formInline.total, '1111111');
					}
				})
			},
			//开票类型选择
			radioEvt(e) {
				if (e === '1') {
					this.$nextTick(() => {
						this.$refs.personal.resetFields()
					})
					this.typeStatus = true

				} else {

					this.$nextTick(() => {
						this.$refs.company.resetFields()
					})
					this.typeStatus = false
				}
			},
			handleClick(e) {
				this.tableData = [];
				this.formInline.page = 1;
				this.formInline.pageSize = 10;
				this.formInline.total = 0;
				this.allMoney = 0;
				if (e.index === '1') {
					this.getListHistoray()
				} else if (e.index === '0') {
					this.getList()
				}
			},
			shengqings(e) {
				console.log(e.unitPrice, '111');
				if (!e.unitPrice) return this.$message('金额为零无法开票')
				this.formLabelAlign.orderId = e.id
				this.formLabelAlign.type = '1'
				this.formLabelAlign.account = ''
				this.formLabelAlign.depositBank = ''
				this.formLabelAlign.email = ''
				this.formLabelAlign.name = ''
				this.formLabelAlign.phone = ''
				this.formLabelAlign.taxnum = ''
				this.formLabelAlign.address = ''
				this.formLabelAlign.remarks = '' //描述
				this.formLabelAlign.buyerTel = ''

				this.$confirm('申请发票之后将不能作废并且不能退款，请确认是否继续申请开票?', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					})
					.then(() => {
						// this.$http.post('getName').then(res => {
						// 	// this.formLabelAlign.name = res.data
						// })
						this.dialogVisible = true
					})
					.catch(() => {
						this.$message({
							type: 'info',
							message: '已取消申请发票'
						});
					});
			},
			more() {
				this.ative = !this.ative
			},
			//申请开票
			shengqing() {
				if (this.allMoney === '0.00') return this.$message('金额为零无法开票！')
				var arr = []
				this.multipleSelection.forEach(item => {
					arr.push(item.id)
				})
				this.formLabelAlign.orderId = arr.join(',')
				this.formLabelAlign.type = ''
				this.formLabelAlign.account = ''
				this.formLabelAlign.depositBank = ''
				this.formLabelAlign.email = ''
				this.formLabelAlign.name = ''
				this.formLabelAlign.phone = ''
				this.formLabelAlign.taxnum = ''
				if (this.formLabelAlign.orderId) {
					this.$confirm('申请发票之后将不能作废并且不能退款，请确认是否继续申请开票?', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {
						this.$http.post('getName').then(res => {
							this.formLabelAlign.name = res.data
						})
						this.dialogVisible = true
					}).catch(() => {
						this.$message({
							type: 'info',
							message: '已取消申请发票'
						});
					});
				} else {
					this.$message({
						type: 'error',
						message: '请勾选需要申请发票的订单'
					});
				}
			},
			// 确认申请开票
			sureBtn() {
				if (this.formLabelAlign.type === '1') {
					if (!this.formLabelAlign.name) return this.$message.error('抬头不能为空')
					if (!this.formLabelAlign.phone) return this.$message.error('手机号不能为空')
					if (!this.formLabelAlign.email) return this.$message.error('邮箱不能为空')
				}
				if (this.formLabelAlign.type === '2') {
					if (!this.formLabelAlign.name) return this.$message.error('抬头不能为空')
					if (!this.formLabelAlign.taxnum) return this.$message.error('税号不能为空')
					if (!this.formLabelAlign.phone) return this.$message.error('手机号不能为空')
					if (!this.formLabelAlign.email) return this.$message.error('邮箱不能为空')
				}

				this.$refs.form.validate((valid) => {
					if (valid) {
						this.formLabelAlign.type = Number(this.formLabelAlign.type)
						this.$http.post('applyForInvoice', this.formLabelAlign).then(res => {
							if (res.code === 200) {
								this.$message.success('开票成功')
								this.dialogVisible = false
								this.typeStatus = true
								this.getList()
							} else {
								this.$message.error(res.message)
								this.dialogVisible = false
								this.typeStatus = true
								this.handleClose()
							}
						}).catch(err => {
							console.log(err, 'err');
							this.$message.error(err)
						})
					}
				})

			},
			toggleSelection(rows) {
				if (rows) {
					rows.forEach(row => {
						this.$refs.multipleTable.toggleRowSelection(row);
					});
				} else {
					this.$refs.multipleTable.clearSelection();
				}
			},
			handleSelectionChange(val) {
				let num = 0
				for (let i in val) {
					num += val[i].unitPrice
				}
				this.allMoney = num.toFixed(2)
				this.multipleSelection = val;
				if (this.tableData.length === val.length) {
					this.checked = true
				} else {
					this.checked = false
				}
			},
			handleSizeChange(val) {
				this.formInline.pageSize = val
				this.formInline.page = 1
				this.getList()
			},
			handleCurrentChange(val) {
				this.formInline.page = val
				this.getList()
			},
			handleSizeChangeHis(val) {
				this.formInline.pageSize = val
				this.formInline.page = 1
				this.getListHistoray()

			},
			handleCurrentChangeHis(val) {
				this.formInline.page = val
				this.getListHistoray()
			},
			// 关闭
			handleClose() {
				this.$refs.form.resetFields()
				this.dialogVisible = false
				this.typeStatus = true
				this.formLabelAlign.orderId = ''
			}
		}

	}
</script>

<style lang="less" scoped>
	.editInfoContent {
		position: relative;
		width: 974px;
		height: 800px;
		overflow: auto;
		background-color: #fff;

		/deep/ .el-tabs__item {
			font-size: 24px;
			padding-bottom: 19px;
			padding-left: 21px !important;
			line-height: 63px;
			height: 63px;
		}

		/deep/ .el-tabs__nav-scroll {
			height: 63px;
		}

		/deep/ .el-tabs__active-bar {
			// left: 24px;
			background-color: #F02610;
		}

		/deep/ .el-tabs__item:hover {
			color: #F02610;
		}

		/deep/.el-tabs__item.is-active {
			color: #F02610;
		}

	}

	.info {
		position: relative;
		overflow-y: auto;
		height: 720px;
		padding: 25px;

		/deep/.el-table th {
			background-color: #EEEEEE;
		}

		/deep/ .el-checkbox__input.is-checked .el-checkbox__inner,
		.el-checkbox__input.is-indeterminate .el-checkbox__inner {
			background-color: #E51E0B;
			border-color: #E51E0B;
		}

		/deep/ .el-table_1_column_7 .el-tooltip {
			color: #E51E0B;
		}

		/deep/.el-checkbox__input.is-checked+.el-checkbox__label {
			color: #000;
		}

		.infoCls {
			height: 600px;
			overflow: auto;
		}

		.checkedAll {
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			padding: 20px 30px 20px 11px;
			background: #EEEEEE;
			display: flex;
			justify-content: space-between;
			align-items: center;


			.bnt1 {
				background: linear-gradient(163deg, #E51E0B 0%, #FF6759 100%);
				color: #fff;
				margin-left: 30px;
			}
		}

		.shengqbtn {
			background: linear-gradient(163deg, #E51E0B 0%, #FF6759 100%);
			color: #fff;
			margin-left: 30px;
		}

		/deep/.cell {
			text-align: center;
		}
	}

	.infoL {
		width: 450px;
		margin: 0 auto;

		.fontStyle {
			color: #E51E0B;
			font-size: 14px;
			margin-top: 37px;
			margin-left: 30px;
			margin-bottom: 30px;
		}

		.bnt {
			background: linear-gradient(163deg, #E51E0B, #F45849);
			color: #fff;
		}
	}

	/deep/ .el-radio__input.is-checked .el-radio__inner {
		border-color: #E51E0B;
		background: #E51E0B;
	}

	/deep/ .el-radio__input.is-checked+.el-radio__label {
		color: #000;
	}

	.more_title {
		cursor: pointer;
		border: 1px solid #ccc;
		padding: 0 20px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		height: 50px;
		background: #ececec;
	}

	.noTeacter {
		// margin-left: 45%;
		color: #666;
		height: 60vh;
		text-align: center;
		margin-top: 200px;
		font-size: 14px;
		color: #ccc;

		.noTeacter_img {
			img {
				width: 188px;
				height: 141px;
				margin-bottom: 10px;
			}
		}
	}

	.more_content {
		padding: 10px;
		margin-top: 20px;
		transition: all 1s ease;


	}

	.no_content {

		display: none;
	}

	@font-face {
		font-family: "iconfont";
		/* Project id 2866159 */
		src: url('//at.alicdn.com/t/font_2866159_sjqdrcd5ros.woff2?t=1641452820036') format('woff2'),
			url('//at.alicdn.com/t/font_2866159_sjqdrcd5ros.woff?t=1641452820036') format('woff'),
			url('//at.alicdn.com/t/font_2866159_sjqdrcd5ros.ttf?t=1641452820036') format('truetype');
	}

	.iconfont {
		font-family: "iconfont" !important;
		font-size: 16px;
		font-style: normal;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}

	.icon-shangjiantou:before {
		content: "\e600";
	}

	.icon-xiajiantou:before {
		content: "\e601";
	}

	/deep/ .el-table__header {
		width: 100% !important;
	}

	/deep/ .el-table__body {
		width: 100% !important;
	}

	/deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
		background: #E51E0B;
	}
</style>
